import * as React from 'react';
import { Link } from 'gatsby';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import { useEffect, useState } from 'react';
import TestResult from "../../components/test-result";

const NavigatorPage = () => {
  const [tests, setTests] = useState({
    'navigator.product': {
      value: '',
      passed: undefined,
    },
    'navigator.productSub': {
      value: '',
      passed: undefined,
    },
    'navigator.vendor': {
      value: '',
      passed: undefined,
    },
    'navigator.vendorSub': {
      value: '',
      passed: undefined,
    },
    'navigator.deviceMemory': {
      value: '',
      passed: undefined,
    },
    'navigator.userAgentData.platformVersion': {
      value: '',
      passed: undefined,
    },
    'navigator.userAgentData.uaFullVersion': {
      value: '',
      passed: undefined,
    },
    'navigator.userAgentData.fullVersionList': {
      value: '',
      passed: undefined,
    },
    'navigator.maxTouchPoints': {
      value: '',
      passed: undefined,
    },
    'navigator.plugins': {
      value: '',
      passed: undefined,
    }
  });


  useEffect(() => {
    async function fetchData() {
      let newState = { ...tests };

      newState['navigator.product']['value'] = navigator.product;
      newState['navigator.product']['passed'] = navigator.product === '';

      newState['navigator.productSub']['value'] = navigator.productSub;
      newState['navigator.productSub']['passed'] = navigator.productSub === '';

      newState['navigator.vendor']['value'] = navigator.vendor;
      newState['navigator.vendor']['passed'] = navigator.vendor === '';

      newState['navigator.vendorSub']['value'] = navigator.vendorSub;
      newState['navigator.vendorSub']['passed'] = navigator.vendorSub === '';

      newState['navigator.deviceMemory']['value'] = String(navigator.deviceMemory);
      newState['navigator.deviceMemory']['passed'] = navigator.deviceMemory === undefined;

      if (navigator.userAgentData) {
        const uaData = await navigator.userAgentData.getHighEntropyValues([
          'platformVersion',
          'fullVersionList',
          'uaFullVersion',
        ]);

        newState['navigator.userAgentData.platformVersion']['value'] = String(uaData.platformVersion);
        newState['navigator.userAgentData.platformVersion']['passed'] = /^\d+(\.0){2}$/.test(uaData.platformVersion);

        newState['navigator.userAgentData.uaFullVersion']['value'] = String(uaData.uaFullVersion);
        newState['navigator.userAgentData.uaFullVersion']['passed'] = /^\d+(\.0){3}$/.test(uaData.uaFullVersion);

        newState['navigator.userAgentData.fullVersionList']['value'] = JSON.stringify(uaData.fullVersionList);
        for (const item of uaData.fullVersionList) {
          const passed = /^\d+(\.0){3}$/.test(item.version);
          newState['navigator.userAgentData.fullVersionList']['passed'] = passed;

          if (!passed) {
            break;
          }
        }
      } else {
        newState['navigator.userAgentData.platformVersion']['passed'] = true;
        newState['navigator.userAgentData.uaFullVersion']['passed'] = true;
        newState['navigator.userAgentData.fullVersionList']['passed'] = true;
      }


      newState['navigator.maxTouchPoints']['value'] = String(navigator.maxTouchPoints);
      newState['navigator.maxTouchPoints']['passed'] = navigator.maxTouchPoints === 0 || navigator.maxTouchPoints === 5;

      newState['navigator.plugins']['value'] = String(navigator.plugins);
      newState['navigator.plugins']['passed'] = !(navigator.plugins instanceof PluginArray);

      setTests(newState);
    }

    fetchData();
  }, []);

  return (
    <Layout>
      <h1>navigator properties</h1>

      <p>
        Crumbs anonymizes some of your browser characteristics in order to
        protect you from being uniquely identifiable on the internet.
      </p>

      <button
        className="bg-emerald-600 text-white w-full mb-10 mt-5"
        onClick={() => {
          window.location.reload();
        }}
      >
        Run test
      </button>

      <table className="table-fixed border-collapse border-2 text-left w-full mb-10">
        <thead className="bg-slate-200">
        <tr>
          <th className="border border-slate-300 font-semibold p-4">Property</th>
          <th className="border border-slate-300 font-semibold p-4">Value</th>
          <th className="border border-slate-300 font-semibold p-4">Test result</th>
        </tr>
        </thead>
        <tbody>
        {
          Object.keys(tests).map((test) => (
            <tr className="border-2" key={test}>
              <td className="border p-4">
                <code>{test}</code>
              </td>
              <td className="border p-4">
                {
                  tests[test].value ?
                    <code>{tests[test].value}</code> :
                    <small><i>(empty)</i></small>
                }
              </td>
              <TestResult result={tests[test].passed} colorStyle="bg">
                <td className="border p-4 text-white">
                  {tests[test].passed === undefined ? 'In progress' : tests[test].passed ? 'Passed' : 'Failed'}
                </td>
              </TestResult>
            </tr>
          ))
        }
        </tbody>
      </table>

      <Link to="/">
        Go back
      </Link>
    </Layout>
  );
}

export const Head = () => <Seo title="navigator properties"/>
export default NavigatorPage;
